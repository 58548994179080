import translate from "@/utils/translation"
import { useRouter } from "next-translate-routes"
import React, { useRef } from "react"
import { CashStack, HousesFill } from "react-bootstrap-icons"
import { MdOutlineAutoGraph, MdOutlineConstruction } from "react-icons/md"
import styled from "styled-components"
import Slider from "react-slick"
import CurrencyFormat from "react-currency-format"

const PerformanceSlider = ({ totalInvestment }) => {
  const settings = {
    className: "center",
    centerPadding: "60px",
    slidesToShow: 2,
    autoplay: true,
    infinite: true,
    speed: 400,
    autoplaySpeed: 5000,
    swipeToSlide: true,
    dots: true,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
    ],
  }

  const slider = useRef()

  return (
    <App ref={slider} className="row text-center pt-0 mt-0 d-block d-md-none">
      <div className="line"></div>
      <Slider ref={c => (slider.current = c)} {...settings}>
        <div className="pt-3 pt-m-0 col-md-6 col-lg-4 col-xl-3  col-12 col-md-4 mt-2 p-1 p-md-2 pb-3 pt-0 p-lg-3 listing">
          <MdOutlineAutoGraph size={30} className="mb-3 mt-2" color="#FB8501" />
          <p className="amount">{translate("homepage_performance_return")}%</p>
          <p> {translate("homepage_performance_return_title")}</p>
        </div>
        <div className="pt-3 pt-m-0 col-md-6 col-lg-4 col-xl-3 col-12 col-md-4 mt-2 p-1 p-md-2 pb-3 pt-0 p-lg-3 listing">
          <CashStack size={30} className="mb-3 mt-2" color="#FB8501" />
          <p className="amount">
            €{translate("homepage_performance_invested")}
          </p>
          <p>{translate("homepage_performance_invested_title")}</p>
        </div>
        <div className="pt-3 pt-m-0 col-md-6 col-lg-4 col-xl-3 col-12 col-md-4 mt-2 p-1 p-md-2 pb-3 pt-0 p-lg-3 listing">
          <MdOutlineConstruction
            size={30}
            className="mb-3"
            style={{ marginTop: "0.5rem" }}
            color="#FB8501"
          />
          <p className="amount">
            {translate("homepage_performance_under_construction")}
          </p>
          <p>{translate("homepage_performance_under_construction_title")}</p>
        </div>
        <div className="pt-3 pt-m-0 col-md-6 col-lg-4 col-xl-3 col-12 col-md-4 mt-2 p-1 p-md-2 pb-3 pt-0 p-lg-3 listing">
          <HousesFill size={30} className="mb-3 mt-2" color="#FB8501" />
          <p className="amount">{translate("homepage_performance_pipeline")}</p>
          <p>{translate("homepage_performance_pipeline_title")}</p>
        </div>
      </Slider>
      <div className="line mt-5"></div>
    </App>
  )
}

const App = styled.div`
  padding: 1.5rem 0 1rem;
  margin-top: 1rem;
  margin-left: 0;
  margin-right: 0;

  .slick-dots {
  left:-5px !important;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;

    //fade left and right
    background: linear-gradient(to right, transparent, #f0f0f0, transparent);

    @media(
        max-width: 768px
        ) {
            background: #f0f0f0;
        }
    )
  }

  p {
    color: #656565;
    margin: 0;
    font-weight: 300;
    font-size: 0.875rem;
  }

  .amount {
    font-size: 1.25rem;
    font-weight: 500;
  }

  position: relative;
`
export default PerformanceSlider
