// components/reviews/Widget.jsx
import React from "react"
import styled from "styled-components"
import { StarFill, StarHalf } from "react-bootstrap-icons"
import translate from "@/utils/translation"
import { Avatar } from "@mui/material"

export default function Widget({ emblaRef }) {
  const trustpilotLink =
    "https://nl.trustpilot.com/review/valvestcapital.com?languages=all"

  return (
    <App>
      <div className="embla" ref={emblaRef}>
        <div className="embla__container">
          {/* Review Item 1 */}
          <div className="embla__slide">
            <div className="inner">
              <div className="stars">
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
              </div>
              <p className="mt-3 mb-3">
                {translate("reviews_item_1_description")}
              </p>
              <div className="d-flex">
                <Avatar
                  className="avatar"
                  src="/pages/homepage/reviews/1.webp"
                  alt={translate("reviews_item_1_name")}
                />
                <div className="name ps-3">
                  <h5 style={{ marginBottom: "0" }}>
                    {translate("reviews_item_1_name")}
                  </h5>
                  <p style={{ fontSize: "0.875rem", color: "737780" }}>
                    {translate("reviews_investor")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Review Item 2 */}
          <div className="embla__slide">
            <div className="inner">
              <div className="stars">
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
                <StarHalf size={20} color="#FEB800" />
              </div>
              <p className="mt-3 mb-3">
                {translate("reviews_item_2_description")}
              </p>
              <div className="d-flex">
                <Avatar
                  className="avatar"
                  src="/pages/homepage/reviews/2.webp"
                  alt={translate("reviews_item_2_name")}
                />
                <div className="name ps-3">
                  <h5 style={{ marginBottom: "0" }}>
                    {translate("reviews_item_2_name")}
                  </h5>
                  <p style={{ fontSize: "0.875rem", color: "737780" }}>
                    {translate("reviews_investor")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Review Item 3 */}
          <div className="embla__slide">
            <div className="inner">
              <div className="stars">
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
              </div>
              <p className="mt-3 mb-3">
                {translate("reviews_item_3_description")}
              </p>
              <div className="d-flex">
                <Avatar
                  className="avatar"
                  src="/pages/homepage/reviews/3.webp"
                  alt={translate("reviews_item_3_name")}
                />
                <div className="name ps-3">
                  <h5 style={{ marginBottom: "0" }}>
                    {translate("reviews_item_3_name")}
                  </h5>
                  <p style={{ fontSize: "0.875rem", color: "737780" }}>
                    {translate("reviews_investor")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Review Item 4 */}
          <div className="embla__slide">
            <div className="inner">
              <div className="stars">
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
                <StarHalf size={20} color="#FEB800" />
              </div>
              <p className="mt-3 mb-3">
                {translate("reviews_item_4_description")}
              </p>
              <div className="d-flex ">
                <Avatar
                  className="avatar"
                  src="/pages/homepage/reviews/4.webp"
                  alt={translate("reviews_item_4_name")}
                />
                <div
                  className="name"
                  onClick={() => window.open(trustpilotLink, "_blank")}
                  role="button"
                  tabIndex={0}
                >
                  <div className="name ps-3">
                    <h5 style={{ marginBottom: "0" }}>
                      {translate("reviews_item_4_name")}
                    </h5>
                    <p style={{ fontSize: "0.875rem", color: "737780" }}>
                      {translate("reviews_investor")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="embla__slide">
            <div className="inner">
              <div className="stars">
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
                <StarFill size={20} color="#FEB800" />
              </div>
              <p className="mt-3 mb-3">
                {translate("reviews_item_5_description")}
              </p>
              <div className="d-flex ">
                <Avatar
                  className="avatar"
                  src="/pages/homepage/reviews/7.webp"
                  alt={translate("reviews_item_4_name")}
                />
                <div
                  className="name"
                  onClick={() => window.open(trustpilotLink, "_blank")}
                  role="button"
                  tabIndex={0}
                >
                  <div className="name ps-3">
                    <h5 style={{ marginBottom: "0" }}>
                      {translate("reviews_item_5_name")}
                    </h5>
                    <p style={{ fontSize: "0.875rem", color: "737780" }}>
                      {translate("reviews_investor")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </App>
  )
}

const App = styled.div`
  margin-top: 1rem;
  max-height: auto;

  @media (max-width: 780px) {
    margin-top: 2.5rem;
  }

  .embla {
    overflow: hidden;
  }

  .embla__container {
    display: flex;
    gap: 15px; /* Space between slides */
    @media (min-width: 768px) {
      margin-top: 1rem;
    }
  }

  .embla__slide {
    flex: 0 0 33.333%; /* Default: 3 slides */
    transition: transform 0.3s;

    padding: 0 3rem 0 0;

    @media (max-width: 1200px) {
      flex: 0 0 50%; /* ~1.6 slides */
      padding: 0 2rem 0 0;
    }

    @media (max-width: 768px) {
      flex: 0 0 75%; /* ~1.4 slides */
      padding: 0 1rem 0 0;
    }

    @media (max-width: 480px) {
      flex: 0 0 90%; /* ~1.2 slides */
      padding: 0 0rem 0 0;
    }

    &:first-child {
      @media (max-width: 768px) {
        margin-left: 1rem;
      }
    }
  }

  .item {
    padding: 10px 0;

    .inner {
      min-height: 250px;
      background: #fffbf8;
      border-radius: 10px;
      z-index: 1;
      border: 0.25px solid #fcc17f;
      padding: 3rem;

      .name {
        margin-left: 1rem;
        margin-top: 0.45rem;
        cursor: pointer;

        h5 {
          font-size: 1.125rem;
          font-weight: 500;
          margin: 0 !important;
        }

        p {
          font-size: 0.875rem;
          color: #777;
        }
      }

      .avatar {
        margin-top: 0.5rem;
        width: 60px;
        height: 60px;
      }

      @media (max-width: 768px) {
        min-height: 330px;
      }
    }

    .stars {
      display: flex;
      gap: 5px;
    }

    p {
      font-size: 1rem;
      margin-top: 1.25rem;
      color: #454f62;
      line-height: 1.7rem;
    }

    h5 {
      line-height: 1rem;
      font-size: 1.125rem;
      padding-top: 0.5rem;
      margin: 0.25rem 0 0;
      padding-bottom: 0rem !important;
    }

    small {
      font-size: 0.875rem;
      color: #777;
    }
  }
`
