import React from "react"
import styled from "styled-components"
import Contact from "./Contact"
import QuestionWrapper from "./Questions/Wrapper"
import AFMBanner from "@/components/AFMBanner/AFMBanner"
import { Button } from "@mui/material"
import translate from "@/utils/translation"
import { ArrowRight } from "react-bootstrap-icons"
import { useRouter } from "next-translate-routes"
import TagManager from "react-gtm-module"
import { useSession } from "next-auth/react"

const Faq = () => {
  const router = useRouter()
  const { data: session } = useSession()

  return (
    <Wrapper>
      <div className="container">
        <div className="row m-0">
          <div className="offset-md-3 col-md-6 col-12 p-md-0">
            <QuestionWrapper />
          </div>
          {/* <div className="offset-md-1 col-md-6 col-12  p-md-0">
            <Contact />
          </div> */}
        </div>
      </div>
      <div className="container mt-5 mt-md-5">
        <div className="row m-0">
          <div className="col-12 col-md-6 offset-md-3 text-center">
            <AFMBanner />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Faq

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 3rem;

  padding: 4rem 0 2rem;
  background-color: #fbfaf8;

  h2 {
    font-size: 1.8rem;
    line-height: 1.4;
  }

  @media (max-width: 992px) {
    padding: 3rem 0 1rem;

    h2 {
      font-size: 1.6rem;
    }
  }

  .more {
    cursor: pointer;
  }
`
