import translate from "@/utils/translation"
import React from "react"
import styled from "styled-components"
import { Avatar } from "@mui/material"
import MailIcon from "@/public/mail.svg"
import PhoneIcon from "@/public/phone_in_talk.svg"
import Lines from "@/public/vectorLines.svg"
import Image from "next/image"
import { FaWhatsapp } from "react-icons/fa"
import TagManager from "react-gtm-module"
import { useSession } from "next-auth/react"
import profile from "@/public/pages/about/employees/Jordan.jpg"

const Contact = () => {
  const { data: session } = useSession()

  return (
    <Wrapper>
      <div className="background" />
      <div>
        <div className="textarea col-12 col-lg-6 mt-lg-auto mb-lg-auto">
          <div className="d-flex">
            <Avatar
              src={profile.src}
              sx={{ width: 60, height: 60 }}
              className=" mt-md-0"
              alt="Jordan"
            />
            <div className="align-content-center">
              <h3 className="ms-3 header">{translate("contact_ready")}</h3>
              <h5 className="ms-3 subheader">
                {translate("contact_ready_sub")}
              </h5>
            </div>
          </div>
          <div className="mt-4 ms-2 items">
            <div className="d-flex item">
              <div>
                <div className="icon">
                  <Image
                    src={PhoneIcon}
                    width="25"
                    height="25"
                    color="#FB8500"
                    alt="phone"
                  />
                </div>
              </div>
              <div className="contact">
                <a
                  href="tel:+31636511734"
                  target="_blank"
                  onClick={() => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "homepage_contact_call",
                        user_id: session?.uid,
                      },
                    })
                  }}
                >
                  <p>+31 (0)6 3651 17 34</p>
                  <p className="sub-title">
                    {translate("contact_availability_phone")}
                  </p>
                </a>
              </div>
            </div>
            <div className="d-flex item">
              <div>
                <div className="icon">
                  <FaWhatsapp size="25" color="#FB8500" className="message" />
                </div>
              </div>
              <div className="contact clickable">
                <a
                  href="https://wa.me/31636511734"
                  target="_blanlee"
                  onClick={() => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "homepage_contact_whatsapp",
                        user_id: session?.uid,
                      },
                    })
                  }}
                >
                  <p>WhatsApp</p>
                  <p className="sub-title">
                    {translate("contact_availability_whatsapp")}
                  </p>
                </a>
              </div>
            </div>
            <div className="d-flex item">
              <div>
                <div className="icon">
                  <Image
                    src={MailIcon}
                    width="25"
                    height="25"
                    alt="email"
                    color="#FB8500"
                  />
                </div>
              </div>
              <div className="contact">
                <a
                  href="mailto:contact@valvest.io"
                  target="_blank"
                  onClick={() => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "homepage_contact_email",
                        user_id: session?.uid,
                      },
                    })
                  }}
                >
                  <p>Email</p>
                  <p className="sub-title">Contact@valvest.io</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Contact

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  .background {
    background-image: url(${Lines.src});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 750px;
    width: 750px;
    position: absolute;
    top: -237px;
    left: -228px;
    z-index: 0;
    scale: 1.8;
    rotate: 90deg;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 400;
    z-index: 99999;
  }

  .header {
    margin-top: 0.3rem;
    z-index: 99999;
  }

  .subheader {
    margin-top: -0.2rem;
    font-size: 1rem;
    font-weight: 300;
    z-index: 99999;
  }

  .textarea {
    width: 100%;
    height: 100%;
    position: relative;
    background: white;
    border: 1px solid #efefef;

    padding: 2rem;
    border-radius: 5px;

    @media (max-width: 772px) {
      padding: 1rem;
    }

    ul {
      padding-bottom: 1rem;
      padding-inline-start: 0px;
      list-style-type: none;
      z-index: 99999;

      li {
        margin-top: 1rem;
      }

      svg {
        margin-top: -3px;
      }
    }
  }

  .items {
    z-index: 99999;
    .item {
      margin-top: 2rem;
    }

    line-height: 0.5rem;

    p {
      line-height: 1.5rem;
      margin: 0;
    }

    .contact {
      margin-left: 1.5rem;
    }

    p {
      font-size: 1rem;
      font-weight: 400;
    }

    .sub-title {
      font-size: 0.9rem;
      font-weight: 200;
    }

    .icon {
      background-color: white;
      border-radius: 50%;
      padding: 0.6rem;
    }

    a {
      text-decoration: none;
      color: black;
    }
  }

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`
