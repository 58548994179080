import { useSession } from "next-auth/react"
import { useRouter } from "next/router"
import { React, useEffect, useState } from "react"
import { Accordion } from "react-bootstrap"
import { Translate } from "react-bootstrap-icons"
import TagManager from "react-gtm-module"
import Markdown from "react-markdown"
import styled from "styled-components"

const Question = ({ key, faq }) => {
  const router = useRouter()
  const { data: session } = useSession()
  const [showOriginal, setShowOriginal] = useState(false)

  const LinkRenderer = props => {
    return (
      <a href={props.href} target="_blank" rel="nofollow">
        {props.children}
      </a>
    )
  }

  return (
    <Accordion defaultActiveKey="0" key={key}>
      <Accordion.Item
        eventKey={key}
        onClick={() => {
          TagManager.dataLayer({
            dataLayer: {
              event: `homepage_faq_button_${key + 1}`,
              user_id: session?.uid,
              faqTitle: faq?.title,
            },
          })
        }}
      >
        <Accordion.Header>
          {!showOriginal &&
          faq?.localizations?.find(item => item.locale === router?.locale)
            ? faq?.localizations?.find(item => item.locale === router?.locale)
                .title
            : faq.title}
        </Accordion.Header>
        <Accordion.Body>
          <Markdown
            className="text-body"
            components={{
              a: LinkRenderer,
            }}
          >
            {!showOriginal &&
            faq?.localizations?.find(item => item.locale === router?.locale)
              ? faq?.localizations?.find(item => item.locale === router?.locale)
                  .body
              : faq.body}
          </Markdown>
          <div className="translate">
            {router.locale === "en" ? null : !showOriginal ? (
              <p className="translation-disclaimer">
                <Translate /> Translated with Deepl.{" "}
                <span onClick={() => setShowOriginal(true)}>View original</span>
              </p>
            ) : (
              <p className="translation-disclaimer">
                <Translate /> Translated with Deepl.{" "}
                <span onClick={() => setShowOriginal(false)}>
                  View translation
                </span>
              </p>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default Question
