import { Link } from "next-translate-routes"
import React from "react"
import styled from "styled-components"

const Risk = () => {
  return (
    <Wrapper className="mt-5">
      <div className="container ps-4 pe-4 p-md-0">
        <div className="row">
          <div className="col-md-4">
            <h2>Before you invest</h2>
          </div>
          <div className="col-md-8 mt-2 mt-md-0">
            <p>
              At Valvest, we are open about the risks of real estate investing
              and believe it’s essential for our investors to understand these
              risks and how we protect them before they invest with us.
            </p>
            <Link href="/risks">Read more</Link>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Risk

const Wrapper = styled.div`
  background: #f8f9fa;

  h2 {
    font-size: 2rem;
    line-height: 2.2rem;
    color: #fb8500;
  }

  a {
    color: #fb8500;
  }

  @media (max-width: 992px) {
    h2 {
      font-size: 1.6rem;
      line-height: 1.25;
    }
  }

  padding: 3rem 0;
`
