import translate from "@/utils/translation"
import React from "react"
import { FaEuroSign } from "react-icons/fa"
import { MdCheckBox } from "react-icons/md"
import styled from "styled-components"

const Infobox2 = () => {
  return (
    <Wrapper className="d-flex">
      <div className="title">
        <MdCheckBox size="42" color="green" />
      </div>
      <div className="ms-2 text">
        <h4>{translate("home_banner_infobox2_title")}</h4>
        <p>{translate("home_banner_infobox2_text")}</p>
      </div>
    </Wrapper>
  )
}

export default Infobox2

const Wrapper = styled.div`
  padding: 0.6rem 0.8rem;
  min-width: 180px;
  height: 100%;
  background: white;
  z-index: 99999;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 4rem;

  .title {
    margin-top: -0.1rem;
  }

  .text {
    margin-top: 0rem;
  }

  h4 {
    font-size: 0.8rem;
    margin: 0;
    line-height: 1.2rem;
  }

  p {
    font-size: 0.8rem;
    margin: 0;
  }
`
