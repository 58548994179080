// components/Review.jsx
import styled from "styled-components"
import React from "react"
import Widget from "./reviews/widget"
import ellipseImage from "@/public/pages/homepage/ellipse.svg"
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons"
import translate from "@/utils/translation"
import TagManager from "react-gtm-module"
import { useSession } from "next-auth/react"
import useEmblaCarousel from "embla-carousel-react"
import Autoplay from "embla-carousel-autoplay"

const Review = () => {
  const { data: session } = useSession()

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: false, // Enables infinite looping
      skipSnaps: false,
      dragFree: true,
      infinite: true,
      align: "start",
      speed: 10, // Slide speed in pixels per second
      startIndex: 0, // Active slide at start
    },
    [Autoplay({ playOnInit: true, delay: 8000 })]
  )

  const next = () => {
    if (emblaApi) {
      emblaApi.scrollNext()

      TagManager.dataLayer({
        dataLayer: {
          event: "homepage_reviews_button_next",
          user_id: session?.uid,
        },
      })
    }
  }

  const previous = () => {
    if (emblaApi) {
      emblaApi.scrollPrev()

      TagManager.dataLayer({
        dataLayer: {
          event: "homepage_reviews_button_back",
          user_id: session?.uid,
        },
      })
    }
  }

  return (
    <Wrapper>
      <div className="container">
        <div className="row p-0 m-0">
          <div className="col-12 ps-3 pe-3 p-md-0">
            <div id="reviews" className="header-title">
              <div className="row justify-content-between position-relative">
                <div className="col-12">
                  <h2 className="text-start">
                    {translate("homepage_review_title")}{" "}
                    <span className="text-orange">
                      {translate("homepage_review_title_highlight")}
                    </span>
                  </h2>
                </div>

                <div className="col-12 mt-2 col-md-2 d-flex gap-3 buttons">
                  <div>
                    <ArrowLeftCircle
                      size={30}
                      onClick={previous}
                      role="button"
                      aria-label="Previous Slide"
                    />
                  </div>
                  <div>
                    <ArrowRightCircle
                      size={30}
                      color="#FB8500"
                      onClick={next}
                      role="button"
                      aria-label="Next Slide"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Widget emblaRef={emblaRef} />
      </div>
    </Wrapper>
  )
}

export default Review

const Wrapper = styled.div`
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url(${ellipseImage.src});
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 55%;
  padding: 4.5rem 0 4rem;

  h2 {
    font-size: 1.8rem;
    max-width: 500px;
    line-height: 1.4;
  }

  @media (max-width: 800px) {
    padding: 4rem 0 4rem;

    h2 {
      font-size: 1.6rem;
      line-height: 1.25;
    }
  }

  p {
    text-align: left;
    max-width: 500px;
    font-size: 1.125rem;
    font-weight: 300;
  }
`
