import translate from "@/utils/translation"
import { Slider } from "@mui/material"
import React from "react"
import CurrencyFormat from "react-currency-format"
import { FaCheck } from "react-icons/fa"
import styled from "styled-components"

const SplitBar = ({ listing }) => {
  const fundedAmount =
    parseFloat(
      listing?.splitInvestment?.investmentAmount ||
        listing?.financials?.investmentAmount
    ) - listing?.splitInvestment?.remainingInvestmentAmount

  const fundingPercent =
    (fundedAmount /
      parseFloat(
        listing?.splitInvestment?.investmentAmount ||
          listing?.financials?.investmentAmount
      )) *
    100

  const totalRaisedAmount =
    listing?.splitInvestment?.finishedTranches
      ?.filter(item => item.status === "closed" && item?.type !== "bank")
      ?.reduce((acc, item) => acc + parseFloat(item.investmentAmount), 0) +
    fundedAmount

  const totalInvestmentAmount = listing?.splitInvestment?.finishedTranches
    ?.filter(item => item?.type !== "bank")
    .reduce((acc, item) => acc + parseFloat(item.investmentAmount), 0)

  const totalFundingPercent = (totalRaisedAmount / totalInvestmentAmount) * 100

  const totalRemainingAmount = totalInvestmentAmount - totalRaisedAmount

  const fundPercent =
    listing.status === "sold" || listing?.status === "reserved"
      ? 100
      : totalFundingPercent

  const investors =
    listing?.status === "bidding" &&
    listing?.splitInvestment?.totalInvestors > 0
      ? listing?.splitInvestment?.totalInvestors
      : 0

  const sliderValue =
    fundingPercent > fundPercent ? fundingPercent : fundPercent

  return (
    <>
      <Bar className="bar">
        <div className="d-flex mt-2 mb-2">
          <Slider className="slider" value={parseFloat(sliderValue)} />
        </div>
        <div className="row">
          {fundPercent === 100 && investors ? (
            <div className="fundPercent d-flex col-12">
              <p>
                <div>
                  <FaCheck
                    color="#39CF83"
                    className="me-1"
                    style={{ marginTop: "-2px" }}
                    size="12"
                  />
                  <span className="bold">
                    {" " + investors}{" "}
                    {translate("listing_single_summarybox_investors") + " "}
                  </span>
                  {translate("listing_single_summarybox_investors_funded")}
                </div>
              </p>
            </div>
          ) : (
            <>
              <div className="fundPercent d-flex col-12 pe-0">
                <p>
                  <span className="bold">
                    {" " + fundPercent?.toFixed(0)}%{" "}
                  </span>
                  {translate("listing_single_summarybox_financed")}
                  {investors > 0 && (
                    <>
                      ,<span className="bold">{" " + investors} </span>
                      {translate("listing_single_summarybox_investors")}
                    </>
                  )}
                </p>
              </div>
            </>
          )}
        </div>
      </Bar>
    </>
  )
}

export default SplitBar

const Bar = styled.div`
  width: 100%;

  .slider {
    padding: 0 0 0.2rem 0;
    height: 8px;
    color: #39cf83;
  }

  .MuiSlider-thumb {
    display: none;
    cursor: none;

    :hover,
    .Mui-active {
      display: hidden;
      cursor: none;
    }
  }

  .fundPercent {
    font-size: 0.7rem;
    font-weight: 400;
    color: #737780;
  }

  .bold {
    font-weight: 600;
  }

  .funded {
    width: 100%;
    height: 10px;
    background-color: rgb(251, 133, 0);
    border-left: 3px solid white;

    :first-child {
      border-left: none;
      border-radius: 10px 0 0 10px;
    }

    :last-child {
      border-radius: 0px 10px 10px 0;
    }
  }

  .remaining {
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    border-left: 3px solid white;

    :last-child {
      border-left: none;
      border-radius: 0px 10px 10px 0;
    }
  }

  p {
    margin: 0;
  }
`
