import HeaderNavigation from "@/components/common/Layout/header/HeaderNavigation"
import Faq from "@/components/homepage/Faq/Faq"
import Highlights from "@/components/homepage/Listings"
import Homebanner from "@/components/homepage/banner/Homebanner"
import Performance from "@/components/homepage/performance/performance"
import CTA from "@/components/homepage/CTA/CTA"
import DealSlider from "@/components/homepage/ListingsMobile/Dealslider"
import { useContext, useEffect, useState } from "react"
import { Context } from "@/context/state"
import Benefits from "@/components/homepage/benefits/benefits"
import Review from "@/components/homepage/Review"
import VideoV2 from "@/components/homepage/video/VideoV2"
import RegisterWidget from "@/components/common/auth/RegisterWidget.js/RegisterWidget"
import { X } from "react-bootstrap-icons"
import styled from "styled-components"
import PerformanceSlider from "@/components/homepage/performance/performanceSlider"
import Risk from "@/components/homepage/risk/Risk"
import HomebannerTest from "@/components/homepage/banner/HomebannerTest"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

const Home = ({ session }) => {
  const { state, dispatch } = useContext(Context)

  const [showRegisterModal, setRegisterModal] = useState(false)

  useEffect(() => {
    if (!state?.listings) {
      const fetchData = async () => {
        dispatch({ type: "SET_LOADING", payload: true })

        try {
          const response = await fetch("/api/listings?split=true&limit=4")
          if (!response.ok) {
            throw new Error("Network response was not ok")
          }
          const result = await response.json()
          const projects = result.sort(
            (a, b) =>
              new Date(b?.splitInvestment?.expirationDate) -
              new Date(a?.splitInvestment?.expirationDate)
          )

          dispatch({
            type: "SET_LISTINGS",
            payload: projects.length ? projects : [],
          })
        } catch (err) {
          dispatch({ type: "SET_LISTINGS", payload: [] })
        } finally {
          dispatch({ type: "SET_LOADING", payload: false })
        }
      }

      fetchData()
    }
  }, [state.listings, dispatch])

  return (
    <>
      <HeaderNavigation />
      <HomebannerTest setRegisterModal={setRegisterModal} />
      <Performance />
      <Highlights setRegisterModal={setRegisterModal} />
      <DealSlider setRegisterModal={setRegisterModal} />
      <VideoV2 />
      {showRegisterModal && (
        <Modal>
          <div className="inner">
            <Back
              onClick={() => setRegisterModal(false)}
              className="clickable"
              size={30}
            />
            <RegisterWidget />
          </div>
        </Modal>
      )}
      <Benefits />
      <Review />
      <Faq />
      <CTA />
    </>
  )
}

export default Home

const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 30%);
  backdrop-filter: blur(4px);
  z-index: 10000000;
  top: 0px;
  left: 0px;

  .headerBar {
    box-shadow: none !important;
  }

  .inner {
    overflow: auto;
    max-width: 380px;
    background: white;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    border-radius: 5px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0em;

    @media (min-width: 768px) {
      position: relative;
    }
  }

  .loginForm {
    margin-top: 1rem;
    box-shadow: 0 0 0 0 !important;
  }
`

const Back = styled(X)`
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 99999;
`
