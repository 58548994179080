import { Avatar } from "@mui/material"
import React from "react"
import { StarFill, StarHalf } from "react-bootstrap-icons"
import styled from "styled-components"
import Modal from "./modal/modal"
import translate from "@/utils/translation"

const Reviews = () => {
  const [showModal, setModal] = React.useState(false)

  return (
    <Wrapper className="d-flex mt-md-4 mt-0">
      <Modal modal={showModal} setModal={setModal} />
      <div className="d-flex avatars mt-2">
        <Avatar
          className="avatar"
          onClick={() =>
            setModal({
              show: true,
              review: 1,
            })
          }
          sx={{
            bgcolor: "orange",
            fontSize: "1rem",
          }}
          src="./pages/homepage/reviews/1.webp"
        />
        <Avatar
          className="avatar"
          onClick={() =>
            setModal({
              show: true,
              review: 2,
            })
          }
          src="./pages/homepage/reviews/2.webp"
        />
        <Avatar
          src="./pages/homepage/reviews/3.webp"
          className="avatar"
          onClick={() =>
            setModal({
              show: true,
              review: 3,
            })
          }
          sx={{
            bgcolor: "orange",
            fontSize: "1rem",
          }}
        />
        <Avatar
          className="avatar"
          src="./pages/homepage/reviews/4.webp"
          onClick={() =>
            setModal({
              show: true,
              review: 4,
            })
          }
          sx={{
            bgcolor: "orange",
            fontSize: "1rem",
          }}
        />
        <Avatar
          className="avatar"
          onClick={() =>
            setModal({
              show: true,
              review: 5,
            })
          }
          src="./pages/homepage/reviews/5.webp"
        />
      </div>
      <div className="ms-3 text mt-2">
        <div>
          <StarFill className="ms-1" color="#FFB800" size={14} />
          <StarFill className="ms-1" color="#FFB800" size={14} />
          <StarFill className="ms-1" color="#FFB800" size={14} />
          <StarFill className="ms-1" color="#FFB800" size={14} />
          <StarHalf className="ms-1" color="#FFB800" size={14} />
        </div>
        <p>{translate("home_banner_review_join_us")}</p>
      </div>
    </Wrapper>
  )
}

export default Reviews

// Styled Components for Reviews
const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: start;

  @media (max-width: 768px) {
    justify-content: center;
  }

  p {
    font-weight: 300;
    color: #585858;
    margin: 0;
    font-size: 0.8rem;
  }

  .text {
    svg:first-child {
      margin: 0 !important;
    }
  }

  .avatars {
    div:not(:first-child) {
      margin-left: -17.5px; /* Adjust overlap */
    }

    .avatar {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      cursor: pointer;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
`
